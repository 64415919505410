import React, { useContext, useState } from "react";
import { Col, Form, Input, Modal, Row, DatePicker } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Context } from "../../store/context";
import http from "../../services/http";

import LicitacionesItem from "./LicitacionesItem";
import LicitacionesForm from "./LicitacionesForm";

import * as moment from "moment";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";

import "./Licitaciones.scss";
import { showWarning } from "../../services/notification";

const Licitaciones = () => {
  const [form] = Form.useForm();

  const [state, setState] = useContext(Context);
  const [current, setCurrent] = useState();
  const [visible, setVisible] = useState(false);

  const onFilterChange = () => {
    const values = form.getFieldsValue();
    values.nombre =
      values.nombre &&
      values.nombre
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

    let nLicitaciones = [...state.licitaciones];
    nLicitaciones = nLicitaciones.map((item) => {
      const desde = parseInt(
        moment(item.desde, "DD/MM/YYYY").format("YYYYMMDD")
      );
      const dhasta = moment(item.hasta, "DD/MM/YYYY");
      const hasta = item.hasta
        ? parseInt(dhasta.format("YYYYMMDD"))
        : undefined;
      const desdeF = values.desde
        ? parseInt(values.desde.format("YYYYMMDD"))
        : undefined;
      const hastaF = values.hasta
        ? parseInt(values.hasta.format("YYYYMMDD"))
        : undefined;
      item.visible =
        (values.nombre === undefined ||
          item.nombre.toLowerCase().includes(values.nombre) ||
          item.nro.toLowerCase().includes(values.nombre)) &&
        (values.desde === undefined || (desde <= desdeF && hasta >= desdeF)) &&
        (values.hasta === undefined || (desde <= hastaF && hasta <= hastaF));
      return item;
    });

    setState({ ...state, licitaciones: nLicitaciones });
  };

  const onClick = async (item) => {
    const response = await http.get(
      `micrositio/inicio/solicitud/documentos?id=${item.id}`
    );

    if (response && response.data.code === 0) {
      const data = response.data.data;

      if (data.solicitudRepetida) {
        showWarning(
          "Ya tenés una solicitud para esta línea de crédito. Revisa tu perfil para verificar su estado."
        );
        return;
      }

      if (data.actividadPermitida === false) {
        Modal.confirm({
          title: "Advertencia",
          icon: <ExclamationCircleOutlined />,
          width: 500,
          content: (
            <div>
              Recuerde que al momento de finalizar la licitación debe estar
              inscripto en la actividad acorde a la misma, para mas información,
              comuníquese con el área de licitaciones.
              <ul>
                <li>
                  Por email a <b>licitaciones@guaymallen.gob.ar</b>
                </li>
                <li>
                  Port teléfono a <b>2614498238</b> o <b>2613072402</b>
                </li>
              </ul>
            </div>
          ),
          okText: "Aceptar",
          cancelButtonProps: { style: { display: "none" } },
        });
      }

      setState({
        ...state,
        documentos: data.documentos,
        documentosAsociados: data.documentosAsociados,
      });

      setCurrent(item);
      setVisible(true);
    }
  };

  const onClose = () => {
    setCurrent();
    setVisible(false);
  };

  return (
    <div className="inicio-licitaciones">
      <div className="container">
        <h1>Contrataciones vigentes</h1>

        <div className="filtros">
          <Form form={form} layout="vertical">
            <Row gutter={[24]}>
              <Col xs={24}>
                <Form.Item label="Nombre" name="nombre">
                  <Input onChange={onFilterChange} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="Número" name="numero">
                  <Input onChange={onFilterChange} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="Vigencia Desde" name="desde">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    onChange={onFilterChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item label="Vigencia Hasta" name="hasta">
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder=""
                    locale={locale}
                    onChange={onFilterChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>

        <Row gutter={[24, 24]} justify="center">
          {state.licitaciones.map((x) => (
            <Col key={x.id} xs={24} md={12} lg={8}>
              <LicitacionesItem item={x} onClick={onClick} />
            </Col>
          ))}
        </Row>

        <Modal
          className="licitaciones-solicitud-form"
          centered
          destroyOnClose={true}
          footer={null}
          width={1200}
          visible={visible && current}
          onCancel={onClose}
        >
          <LicitacionesForm current={current} onClose={onClose} />
        </Modal>
      </div>
    </div>
  );
};

export default Licitaciones;
