import React, { useState } from "react";
import { Modal, Tag, Tooltip } from "antd";
import { SlideDown } from "react-slidedown";
import { FiDownload, FiEye, FiTrash2 } from "react-icons/fi";
import { FileIcon, defaultStyles } from "react-file-icon";

import "./Documento.scss";
import Viewer from "react-viewer";

const Documento = ({
  documento,
  slidedown,
  open,
  single,
  onUpload,
  onDelete,
}) => {
  const [closed, setClosed] = useState(slidedown && !open ? true : false);

  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const termsUrl = process.env.REACT_APP_API_URL.slice(0, -3);

  const images = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".pdf"];

  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  }

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    if (file.extension === ".pdf") {
      setPdfVisible(true)
    }
    else {

      setPreviewVisible(true);
    }
  };


  const renderStatusDocumento = () => {
    let color = "green";
    let text = "Completo";

    if (documento.pendiente) {
      color = "orange";
      text = "Pendiente";
    }

    return (
      <Tag className="status" color={color}>
        {text}
      </Tag>
    );
  };

  const renderObligatoriedad = () => {
    if (!documento.obligatorio) {
      return;
    }

    return (
      <Tag className="obligatorio" color="red">
        Excluyente
      </Tag>
    );
  };

  const renderEstado = (file) => {
    let color = "";
    let text = "";

    switch (file.estado) {
      case 1:
        color = "gold";
        text = "Pendiente";
        break;

      case 2:
        color = "red";
        text = "Observado";
        break;

      case 3:
        color = "green";
        text = "Validado";
        break;

      case 4:
        color = "purple";
        text = "Obsoleto";
        break;

      default:
        break;
    }

    return <Tag color={color}>{text}</Tag>;
  };

  const onClickDownload = (file) => {
    window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${file.id}`,
      "_blank"
    );
  };

  const onClickHeader = () => {
    if (!slidedown) {
      onUpload(documento);
      return;
    }

    setClosed(!closed);
  };

  return (
    <div className="documento">
      <div className="documento-header" onClick={onClickHeader}>
        <div className="documento-icon">
          <FileIcon extension=".pdf" {...defaultStyles.pdf} />
        </div>
        <div className="documento-title">
          <div className="documento-name">{documento.nombre}</div>
          {renderObligatoriedad()}
        </div>
        <div className="documento-status">{renderStatusDocumento()}</div>
      </div>
      
      {slidedown && (
        <SlideDown className="documento-slidedown" closed={closed}>
          <table className="documento-archivos">
            <thead>
              <tr>
                <th>Nombre</th>
                
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {documento.archivos.length === 0 && (
                <tr>
                  <td className="documento-empty" colSpan={7}>
                    No se encontró ningún archivo
                  </td>
                </tr>
              )}
              {documento.archivos.map((archivo, index) => (
                <tr key={index}>
                  <td>{archivo.nombre}</td>
                  {/* <td>{archivo.fecha}</td> */}
                  {/* <td>{archivo.hora}</td> */}
                  {/* <td>{archivo.tamanioTexto}</td> */}
                  <td>{renderEstado(archivo)}</td>
                  
                  <td>
                    <Tooltip title="Descargar">
                      <div
                        className="documento-download"
                        onClick={() => onClickDownload(archivo)}
                      >
                        <FiDownload />
                      </div>
                    </Tooltip>
                  </td>
                  <td>
                    {archivo.estado === 1 && (
                      <Tooltip title="Eliminar">
                        <div
                          className="documento-delete"
                          onClick={() => onDelete(archivo)}
                        >
                          <FiTrash2 />
                        </div>
                      </Tooltip>
                    )}
                  </td>
                  <td>
                    {images.indexOf(archivo.extension) > -1 && (
                      <Tooltip title="Ver">
                        <div
                          className="documento-download"
                          onClick={() =>
                            onPreview(archivo)
                          }
                        >
                          <FiEye />
                        </div>
                      </Tooltip>
                    )}
                  </td>

                </tr>
              ))}
            </tbody>
          </table>

          <Viewer
            visible={previewVisible}
            onClose={() => {
              setPreviewVisible(false);
            }}
            images={[{ src: previewUrl, alt: "" }]}
          />

          {pdfVisible && (
            <React.Fragment>
              <Modal
                className=""
                centered
                destroyOnClose={true}
                footer={null}
                width={1200}
                visible={pdfVisible}
                onCancel={onCloseModalPdf}
              >
                <iframe title='Ver documento' src={previewUrl} frameBorder="0" width="98%" height="600"></iframe>

              </Modal>
            </React.Fragment>)}
          {onUpload &&
            (!single ||
              documento.archivos.length === 0 ||
              documento.pendiente) && (
              <div
                className="documento-upload"
                onClick={() => onUpload(documento) }
              >
                Cargar Archivo
              </div>
            )}
        </SlideDown>

      )}
    </div>
  );
};

export default Documento;
