import React, { useEffect, useState } from "react";
import { FiDownload, FiEye, FiFileText } from "react-icons/fi";
import { FileIcon, defaultStyles } from "react-file-icon";

import http from "../../services/http";

import "./ActaApertura.scss";
import { Modal } from "antd";
import Viewer from "react-viewer";

const ActaApertura = (props) => {
  const { solicitudId } = props;

  const [actas, setActas] = useState([]);

  const [previewUrl, setPreviewUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [pdfVisible, setPdfVisible] = useState(false);

  const images = ["jpg", "jpeg", "bmp", "gif", "png", "pdf"];

  const onCloseModalPdf = async () => {
    setPdfVisible(false);
  };

  const onPreview = (file) => {
    setPreviewUrl(`${process.env.REACT_APP_API_URL}/archivos/${file.id}`);
    if (file.extension === "pdf") {
      setPdfVisible(true);
    } else {
      setPreviewVisible(true);
    }
  };

  const onDownload = (item) => {
    var win = window.open(
      `${process.env.REACT_APP_API_URL}/archivos/${item.id}`,
      "_blank"
    );
    win.focus();
  };

  useEffect(() => {
    async function getList() {
      const url = `micrositio/perfil/solicitudes/${solicitudId}/apertura`;
      const response = await http.get(url);
      if (response && response.data.code === 0) {
        const data = response.data.data;
        setActas(data.actas);
      }
    }
    getList();
  }, [solicitudId]);

  if (actas.length === 0) {
    return null;
  }

  return (
    <div className="actas-apertura">
      <div className="header">
        <div className="icon">
          <FiFileText />
        </div>
        <div className="data">
          <div className="title">Acta de Apertura</div>
          <div className="description">
            Acta con los resultados de la contratación
          </div>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th className="icon"></th>
            <th>Nombre</th>
            <th className="date">Fecha</th>
            <th className="download"></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {actas.map((documento, index) => (
            <tr key={index}>
              <td className="icon">
                <FileIcon
                  extension={documento.extension}
                  {...defaultStyles.pdf}
                />
              </td>
              <td>{documento.nombre}</td>
              <td className="date">{documento.fecha}</td>
              <td className="download">
                <div title="Descargar" onClick={() => onDownload(documento)}>
                  <FiDownload />
                </div>
              </td>
              {images.indexOf(documento.extension) > -1 && (
                <td className="download">
                  {documento.id && (
                    <div title="Ver" onClick={() => onPreview(documento)}>
                      <FiEye />
                    </div>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <Viewer
        visible={previewVisible}
        onClose={() => {
          setPreviewVisible(false);
        }}
        images={[{ src: previewUrl, alt: "" }]}
      />

      {pdfVisible && (
        <React.Fragment>
          <Modal
            className=""
            centered
            destroyOnClose={true}
            footer={null}
            width={1200}
            visible={pdfVisible}
            onCancel={onCloseModalPdf}
          >
            <iframe
              title="Ver documento"
              src={previewUrl}
              frameBorder="0"
              width="98%"
              height="600"
            ></iframe>
          </Modal>
        </React.Fragment>
      )}
    </div>
  );
};

export default ActaApertura;
